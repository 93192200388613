@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
    height: 0.25rem;
}

::-webkit-scrollbar {
    width: 0.25rem;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.5rem 0.5rem 0 0;
}

::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #c1c1c1;
    border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
}

h1 {
    font-size: calc(1.75rem);
    font-weight: 600;
}

h2 {
    font-size: calc(1.25rem);
    font-weight: 700;
}

h3 {
    font-size: calc(1.125rem);
    font-weight: 600;
}

h6 {
    font-size: calc(0.875rem);
    font-weight: 500;
}


p {
    font-size: 0.75rem;
}

:root {
    --text-size-sx: 0.75rem;
    --text-size-sm: calc(0.875rem);
}

button:focus-visible {
    outline: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 1.5rem !important;
    padding: 0.5rem !important;
    color: #1065dd !important;
    fill: black !important;
}

.swiper-button-next {
    background: white !important;
    border-radius: 50% !important;
    width: 44px !important;
    height: 44px !important;
    right: 0 !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.swiper-button-prev {
    background: white !important;
    border-radius: 50% !important;
    width: 44px !important;
    height: 44px !important;
    left: 0 !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
}

.shadow-all {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* .swiper-wrapper{
    margin-bottom: 10px !important;
} */
